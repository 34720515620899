
import React, { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { PriceInfoRefresher } from './PriceInfoBox';

export function PQCheckList(props: any) {
    const lang = props.config.language;
    let transContinue = props.config.config.languageLookup["continue"][lang];
    let className: string = "sh-button-input";

    const [stateChange, setStateChange] = useState({});
    const selectedButtons: any[] = props.page.buttons;

    /*    if (props.page.doSendAll) {
            transContinue = "Send din forespørgsel";
            className += " sh-button-darker-bg";
        } */

    console.log(stateChange);
    let buttonList = (props.page.buttons as any[]).map((value, index) => {
        console.log("button:", value);

        let classNames: string = "sh-button sh-button-checkbox" + (value.isOn ? " sh-button-sel" : "");
        let buttonSize: string = props.page.buttonSize || "22rem";
        return <div style={{ minWidth: buttonSize }} onClick={onButtonCheck} className={classNames} key={value.transText} id={"" + index}>{value.transText + (value.isOn ? " ✔" : "")}</div>


    });

    function onButtonCheck(event: MouseEvent) {
        let key: number = Number((event.target as any).id);
        selectedButtons[key].isOn = !selectedButtons[key].isOn;
        setStateChange({});
        let selectedItems: string[] = selectedButtons.filter(value => value.isOn).map(value => value.transText);

        if (selectedItems) {
            props.page.input = selectedItems.join(", ");
        }
        else {
            props.page.input = "";
        }



        PriceInfoRefresher.refresh();
    }
    let targetLoc: string = "/" + props.page.language + "/" + props.page.target;
    if (props.config.config.overviewPage) {
        targetLoc = props.config.config.overviewPage;
    }


    return <div>
        {buttonList}
        <br />
        <Link to={targetLoc} className={className} >
            {transContinue}
        </Link >

    </div>
}
