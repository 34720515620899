import RLog from "./RLog";

export default class PQConnect {
    static readonly ENDPOINT_PING = "/ping";
    static readonly ENDPOINT_GETUID = "/getUID";
    static readonly ENDPOINT_SENDPAGE = "/sendPage";
    static readonly ENDPOINT_COMPLETE = "/complete";
    static inProgress: any = {};

    constructor(private url: string) {

    }

    ping(uid: string) {
        fetch(this.url + PQConnect.ENDPOINT_PING + "?uid=" + uid)
            .then(res => res.json())
            .then((_result) => {
                //console.log("ping result:", result.status);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("[ping] failed", error);
                }
            )
    }

    sendComplete(config: any, callback?: () => void) {
        fetch(this.url + PQConnect.ENDPOINT_COMPLETE, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'text/plain'
                // 'Content-Type': 'application/json',
            },
            body: JSON.stringify(config),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("Received :", result);
                    if (callback)
                        callback();
                    //callback(result.uid);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                    console.log("Received error:", error);
                    RLog.error("Failed to send Complete command", { url: this.url + PQConnect.ENDPOINT_COMPLETE, rawError: error });
                }
            )
    }

    sendPage(page: any, callback: () => void) {
        let data: any = {
            index: page.fillIndex,
            uid: page.uid,
            position: page.position,
            text: (page.DK ? page.DK : page.transText),
            id: page.id,
            value: "" + (page.input ? page.input : page.value)
        };
        let key: string = data.uid + data.value + data.id;
        if (PQConnect.inProgress[key]) {
            return;
        }
        PQConnect.inProgress[key] = true;

        fetch(this.url + PQConnect.ENDPOINT_SENDPAGE, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'text/plain'
                // 'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    delete PQConnect.inProgress[key];
                    console.log("Received :", result);
                    callback();
                    //callback(result.uid);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    delete PQConnect.inProgress[key];
                    RLog.error("Failed to send page", this.url + PQConnect.ENDPOINT_SENDPAGE);
                }
            )
    }

    retrieveUID(version: number, callback: (uid: string) => void) {
        fetch(this.url + PQConnect.ENDPOINT_GETUID + "?version=" + version)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("Received uid:", result.uid);
                    callback(result.uid);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    RLog.error("[retrieveUID] Received error:", error);
                }
            )
    }
}
