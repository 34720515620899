//import {TweenLite} from "gsap";
import { Eclipse } from "react-loading-io"
import React from "react";
//import { HashRouter as Router, Route, Link, useHistory } from "react-router-dom";
import { HashRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import { ConfigHelper } from "./ConfigHelper";
import PQContainer from "./PQContainer";
import PQMulti from "./PQMulti";
import PQTitle from "./PQTitle";
import { PQNumberInput, PQTextInput } from "./PQComps"
import PriceInfoBox from "./PriceInfoBox";
import { PQCheckList } from "./PQCheckList";
import PQOverview from "./PQOverview";
import { isMobile } from 'react-device-detect';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

//ReactGA.initialize('UA-185801155-1');
ReactGA.initialize('UA-39820924-1');
const history = createBrowserHistory();
var pageUpdated: boolean = true;

history.listen(location => {
    ReactGA.set({ page: location.hash }); // Update the user's current page
    ReactGA.pageview(location.hash); // Record a pageview for the given page
    pageUpdated = true;
});

interface PQFormProps {
    language?: string;
    name?: string;
    status?: string;
    //position?: number;
}

export class PQForm extends React.Component<PQFormProps>
{
    configHelper?: ConfigHelper;
    language: string;
    animationProps: any;
    //myTween: gsap.core.Tween;
    myElement: any = null;
    //pageMaker: PageMaker;
    currentPage: any = {};
    statusReporter: Function;
    priceBoxRef: any;
    priceData: any = {};
    priceBoxUpdate: Function;
    static isForceUpdating: boolean = false;
    mainRef: React.RefObject<HTMLDivElement>;

    constructor(props: PQFormProps) {
        super(props)

        this.mainRef = React.createRef();

        //this.myTween = null;
        console.log("PQForm constructor");

        this.language = props.language || "DK";
        //PageMaker.language = this.language;
        this.state =
        {
            name: props.name,
            status: "Loading... Please wait...",
            position: "init",
            priceData: {}
        }

    }

    private initForm(statusCallback: Function): void {
        this.statusReporter = statusCallback;
        this.statusReporter("initForm");
        this.configHelper = new ConfigHelper();
        this.configHelper.setLanguage(this.language);
        this.configHelper.whenReady().then(() => {
            console.log("Form data ready");
            this.createPage();

            this.configHelper.forceRender = this.forceRerender.bind(this);
        });
    }

    forceRerender(): void {
        //this.forceUpdate();
        this.setState({ status: "update" });
    }

    createPage() {
        this.currentPage = this.configHelper.getNext();

        this.setState({ position: this.currentPage.id });
    }


    componentDidMount() {
        console.log("PQForm did mount");
        this.setState({ status: "ComponentDidMount" });
        this.initForm(this.updateStatus);
        /*   setTimeout(() => {
               this.myTween = TweenLite.from(this.myElement, 0.9, {x: 100, alpha: 0});
               this.setState({ status: "Loading complete...", soemthing: 100 });
               setTimeout(()=>{
                   this.setState({position:1});
                   this.myTween = TweenLite.from(this.myElement, 0.9, {x: 100, alpha: 0});
               }, 2000);

           }, 2000);*/
        //this.myTween = TweenLite.from(this.myElement, 0.9, {x: 100, alpha: 0});

    }
    updateStatus(updateStatus: string) {
        this.setState({ status: "Status:" + updateStatus });
    }
    updatePrice(): void {
        //this.setState({ priceData:  });
        console.log("updatePrice");
    }

    render(): JSX.Element {
        console.log("render():");

        if (this.configHelper?.loading) {
            return <div>
                <div className="sh-mid-center"><Eclipse size={170} custom speed={3.0} width={7} color="#e30f7a" /></div>
                <div className="sh-mid-center"><Eclipse size={170} custom speed={2.19} width={7} color="#02aeef" /></div>
            </div>
        }
        //https://imkrunal.github.io/react-loading-io/?path=/story/eclipse--custom&knob-Size=128&knob-Color=rgba(28,153,27,1)&knob-Speed=1.49&knob-Width=7

        return <div>

            <div className="sh-main" id="sh-main-id" ref={this.mainRef}><HashRouter>
                <Switch>
                    <Route path="/:targetLang/:targetPage/:subtype" component={this.redirectToPage.bind(this)} />
                    <Route path="/:targetLang/:targetPage" component={this.redirectToPage.bind(this)} />
                    <Route path="/:targetLang/" component={this.redirectToPage.bind(this)} />
                    <Route exact path="/" component={this.redirectToPage.bind(this)} />

                </Switch>
            </HashRouter>
            </div>

        </div >

        //            {this.getPage()}
    }

    redirectToPage(props: any): JSX.Element {
        var finalPageImage: JSX.Element = <></>;
        let headerStyle = {};
        let backStyle = {};
        console.log("match:", props.match.params.targetPage)
        /*if (props.match.params.targetPage) {
            }*/
        if (this.configHelper && this.configHelper.isReady()) {

            if (this.configHelper.config && this.configHelper.config.reset) {
                if (!props.match.params || Object.keys(props.match.params).length <= 0) {

                    this.configHelper.config.reset = false;
                }
                else {
                    if (props.match.params.targetPage !== "thank_you") {
                        ReactGA.event({
                            category: 'User',
                            action: 'Restarting form'
                        });

                        return <Redirect to={"/"} />
                    }
                }
            }

            if (props.match.params.targetPage !== "thank_you") {
                this.configHelper.processValues();
            }

            if (props.match.params.targetLang) {
                this.configHelper.setLanguage(props.match.params.targetLang);
            }
            let pageName = props.match.params.targetPage;
            this.configHelper.setCurrentPage(pageName);

            let eventPageName: string = "Initial";
            if (pageName) {
                eventPageName = pageName;
            }


            if (pageUpdated) {
                pageUpdated = false;
                ReactGA.event({
                    category: 'User',
                    action: 'show page: ' + eventPageName
                });

                if (props.match.params.targetPage === "thank_you") {
                    finalPageImage = <img height="1" width="1" alt="" src="https://www.googleadservices.com/pagead/conversion/952659487/?label=UaWkCM2PqnQQn9yhxgM&guid=ON&script=0" />
                }
            }

            this.currentPage = this.configHelper.getNext();
            this.currentPage.subType = props.match.params.subtype;

            this.priceData = this.configHelper.getPriceConfig();

            this.configHelper.persist();

            if (this.configHelper &&
                this.configHelper.config &&
                this.configHelper.config.startPage !== this.configHelper.currentPage) {
                headerStyle = { display: "none" };

                console.log("this.headerStyle", headerStyle);
            }
            if (this.configHelper.currentPage === "thank_you" || this.configHelper.currentPage === this.configHelper.config.startPage) {
                backStyle = { display: "none" };
            }



        }
        /*if (!this.configHelper) {
            return <div></div>
        }*/

        return <div><div className="">
            <PriceInfoBox config={this.configHelper} />
        </div><div className="fade-me">
                <div className="sh-header1" style={headerStyle}>{this.configHelper ? this.configHelper.getLocale("giveOffer") : ""}</div>
                {this.getPage()}
            </div>
            <div className="back-button" style={backStyle}><a href="http://" onClick={(event) => {
                event.preventDefault();
                window.history.back()
            }} ><img alt="Go Back" src="pilen.png" /></a> </div>
            {finalPageImage}
        </div >
    }




    getPage(): JSX.Element {
        console.log("getPage()");

        if (this.configHelper?.loading) {
            return <div>
                <div className="sh-mid-center"><Eclipse size={170} custom speed={3.0} width={7} color="#e30f7a" /></div>
                <div className="sh-mid-center"><Eclipse size={170} custom speed={2.19} width={7} color="#02aeef" /></div>
            </div>
        }


        //let state: any = this.state;

        /*if(state.page)
        {
            return this.pageMaker.getComponent();
            //return <div>{state.page.title}</div>;
        }*/

        if (this.currentPage.subType) {
            this.currentPage.cost = this.currentPage[this.currentPage.subType].cost;
            this.currentPage.value = this.currentPage[this.currentPage.subType].value;
            this.currentPage = { ...this.currentPage, ...this.currentPage[this.currentPage.subType] };
        }

        //this.currentPage.forceUpdate = this.updatePrice.bind(this);

        if (this.currentPage.inputType && this.currentPage.inputType === "number") {
            this.currentPage.type = 'number';
        }

        this.mainRef?.current?.setAttribute("style", "top:3rem");
        switch (this.currentPage.type) {
            case "overview":
                this.mainRef.current.setAttribute("style", "top:-1rem");
                return < PQOverview config={this.configHelper} page={this.currentPage} />
            case "infobox":
                return this.infoBox();
            case "init":
                return <div>Loading...</div>
            case "check":
                return this.buildCheck();
            case "multi":
                return this.buildMulti();
            case "info":
                return this.infoMessage();
            case "number":
                //return <PQNumberInput config={this.configHelper} page={this.currentPage} />
                if (isMobile) {
                    return <PQNumberInput config={this.configHelper} page={this.currentPage} />
                }
                else {
                    return <PQTextInput config={this.configHelper} page={this.currentPage} key={this.currentPage.id} />

                }


            case "text":
                return <PQTextInput config={this.configHelper} page={this.currentPage} key={this.currentPage.id} />;
            //  return this.numberInput();
            /*            case 0:
                            return <div ref={div => this.myElement = div}>  {(this.state as PQFormProps).status} </div>
                        case 1:
                            return <div ref={div => this.myElement = div}>CASE 1 happened</div> */
        }
        return <div></div>
        //return <div>  {(this.state as PQFormProps).status} </div>
    }

    infoBox(): JSX.Element {
        //let lang: string = this.configHelper.language;
        //let header:string =
        return <div className="sh-infobox">{this.currentPage.transText}</div>
    }


    infoMessage(): JSX.Element {
        let lang: string = this.configHelper.language;
        let bullets: any[] = [];
        let ulList: JSX.Element = undefined;
        //let list: any = <ul><li>test</li><li>test</li><li>test</li></ul>
        if (this.currentPage.bullets) {
            bullets = (this.currentPage.bullets[lang] as any[]).map((value, index) => {
                return <li key={"li" + index}>{value}</li>
            });
            ulList = <ul>{bullets}</ul>
        }
        let targetLoc: string = "/" + this.currentPage.language + "/" + this.currentPage.target;
        if (this.configHelper.config.overviewPage) {
            targetLoc = this.configHelper.config.overviewPage;
        }


        let headerText: string = (this.currentPage?.header?.transText === this.currentPage.transText) ? "" : this.currentPage?.header?.transText;
        //let header:string =
        return <div><div className="sh-header">
            {headerText}
        </div>

            <Link to={targetLoc} className="sh-clean-link">
                <div className="sh-infobox"><div>{this.currentPage.transText}</div><div style={{ textAlign: "left" }}>{ulList}</div></div><div className="sh-continue-button">{this.configHelper.config.languageLookup["continue"][lang]}</div>
            </Link ></div>
    }

    onPageSelection(item: any): void {

        //this.config.setCurrentPage(item.target);

        this.createPage();
    }

    changeLanguage(event: MouseEvent): void {
        this.configHelper.setLanguage((event.target as any).id);
        this.currentPage = this.configHelper.getNext();

        this.setState({ position: this.currentPage.id });
    }

    buildCheck(): JSX.Element {
        return <PQContainer>
            <PQTitle page={this.currentPage} />
            <PQCheckList page={this.currentPage} config={this.configHelper} onSelection={this.onPageSelection.bind(this)} />
        </PQContainer>
    }

    buildMulti(): JSX.Element {
        return <PQContainer>
            <PQTitle page={this.currentPage} />
            <PQMulti config={this.configHelper} page={this.currentPage} onSelection={this.onPageSelection.bind(this)} />
            <div className="sh-top">
                {/* <ul>
                    <li><Link to={"/DK/" + this.currentPage.id}>Dansk</Link></li>
                    <li><Link to={"/EN/" + this.currentPage.id}>English</Link></li>
                </ul>
               */}

            </div>
        </PQContainer>

        //      return <div>INSERT MULTI CODE</div>
    }
}
