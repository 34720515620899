import React, { Component } from 'react'
import { PageProp } from './PageProp';

export default class PQTitle extends Component<PageProp> {
    constructor(props: PageProp) {
        super(props);
        this.state =
        {
            page: props.page,
        }
    }

    render() {
        return (
            <div style={{ paddingBottom: "10pt" }}>
                {this.props.page.transText}
            </div>
        )
    }
}
