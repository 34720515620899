import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import './App.css';
import { ConfigHelper } from './PQForm/ConfigHelper';
/*import {MyComponent} from './MyComponent';
import {ShoppingList} from './tests/ShoppingList';
import TestRouter from './tests/TestRouter';
*/
import { PQForm } from './PQForm/PQForm';


function ShowLogo(props: any) {
  if (window.location !== window.parent.location) {
    if (isMobile) {
      window.top.location.href = window.location.href;
    }

    return <div></div>;// The page is in an iframe
  }
  else {
    return <a href="https://www.servicehuset.dk"><img src="sh-logo.png" alt="" className="sh-logo" /></a>
  }
}
//'./sommerhus_web.jpg'
function App(props: any) {
  let [bgImage, setBgImage] = useState({ src: "bg02.jpg", pos: undefined });
  console.log("bgImage", bgImage);
  ConfigHelper.setBgImageCall(setBgImage);
  //let style={{ backgroundImage: "url(" + bgImage + ")" }}

  document.body.style.backgroundImage = "url(" + bgImage.src + ")";
  if (isMobile)
    document.body.style.backgroundSize = "150vh";
  else
    document.body.style.backgroundSize = "cover";

  if (bgImage.pos) {
    document.body.style.backgroundPosition = bgImage.pos;
  } else {
    document.body.style.backgroundPosition = "top";
  }

  return (
    <div className="App">

      <ShowLogo />

      <PQForm language={props.lang} />
    </div>
  );
}


/*<h6>
        ----
          Showing: {props.lang}
          ----</h6>*/

export default App;
