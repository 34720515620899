import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { da } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Link, useHistory } from 'react-router-dom';
import PQAddressHelper from './PQAddressHelper';
import { format, getDay, setHours, setMinutes } from 'date-fns';

export function PQTextInput(props: any) {
    let [submitActive, setSubmitActive] = useState(props.page["value"] && props.page["value"].length > 0);
    const [selectedDate, setSelectedDate] = useState();
    const [inputValue, setInputValue] = useState(props.page["value"]);
    const [stateData, setStateData] = useState({});
    const inputRef = useRef(null);
    let history = useHistory();


    if (!selectedDate) {
        const now = new Date();
        now.setHours(10, 0, 0);
        setSelectedDate(now as any);
    }


    if (props.page.required !== undefined && props.page.required === false) {
        submitActive = true;
    }

    const lang = props.config.language;
    const transContinue = props.config.config.languageLookup["continue"][lang];
    const className: string = "sh-button-input" + (submitActive ? "" : " sh-disabled2");

    const inputType: string = props.page.inputType && props.page.inputType !== 'number' ? props.page.inputType : "text";
    //    const targetLoc: string = props.config.config.formFinished ?"/" + props.page.language + "/" + props.page.target;
    let targetLoc: string = "/" + props.page.language + "/" + props.page.target;

    if (props.page["maxValue"] && props.page["value"] && Number(props.page["value"]) > Number(props.page["maxValue"][0])) {
        targetLoc = "/" + props.page.language + "/" + props.page["maxValue"][1];
    }
    if (props.config.config.overviewPage) {
        targetLoc = props.config.config.overviewPage;
    }
    if (props.page["conditional"] && props.page["value"] && props.page["conditional"][String(props.page["value"]).toLowerCase()]) {
        //targetLoc = "/" + props.page.language + "/" + props.page["maxValue"][1];
        targetLoc = "/" + props.page.language + "/" + props.page.id + "/" + props.page["conditional"][String(props.page["value"]).toLowerCase()];
        //props.page.input = props.page["value"];
    }

    if (props.page.id === "husdyr2") {
        props.page.input = props.page["value"];
    }


    var extraStyle: any = {}
    if (props.page.textAlign) {
        extraStyle["textAlign"] = props.page.textAlign;
    }
    if (props.page.width) {
        extraStyle["width"] = props.page.width;
        extraStyle["minWidth"] = props.page.width;
    }
    if (props.page.height) {
        extraStyle["height"] = props.page.height;
        extraStyle["minHeight"] = props.page.height;
    }

    return (
        <div className="sh-text-input">
            <div className="sh-header">
                {props.page?.header?.transText}
            </div>
            <form onSubmit={onSubmit}>
                <SHInputField />
            </form>

            <Link to={targetLoc} className={className} onClick={event => !submitActive && event.preventDefault()}>
                {transContinue}
            </Link >
        </div>
    );

    function isWeekday(date: Date) {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    }

    function SHInputField() {
        switch (inputType) {
            case 'datetime-local':
                return <div><DatePicker selected={selectedDate}
                    minTime={setHours(setMinutes(new Date(), 0), 7)}
                    maxTime={setHours(setMinutes(new Date(), 0), 19)}

                    inline
                    showTimeSelect
                    locale={da}
                    minDate={new Date()}
                    filterDate={isWeekday}
                    timeFormat="HH:mm" dateFormat="d. MMMM yyyy HH:mm"
                    onChange={datePicked} /></div>
            case 'textArea':
                if (inputValue) {
                    setTimeout(() => { inputRef.current.value = inputValue }, 50);
                }
                return <div><textarea style={extraStyle} ref={inputRef} className="sh-text" onChange={textAreaValueChanged} autoFocus={true} /></div>
            //  case 'number':
            //    return <input style={extraStyle} onClick={onClick} ref={inputRef} type={inputType} className="sh-text" onChange={valueChanged} value={inputValue || ""} autoFocus={true} />

        }
        return <input style={extraStyle} onClick={onClick} ref={inputRef} type={inputType} className="sh-text" onChange={valueChanged} value={inputValue || ""} autoFocus={true} />
    }

    function textAreaValueChanged(event: ChangeEvent): void {
        let value: string = (event.target as any).value;
        //setInputValue(undefined);
        props.page["input"] = props.page["value"] = value;
        console.log("New value:", value);
    }

    function datePicked(date: any) {
        let value: string = format(date as Date, "d. MMMM yyyy HH:mm", { locale: da });
        console.log(`date picked: ${value}`);
        setSelectedDate(date);
        setSubmitActive(true);
        props.page["value"] = value;
        props.page["input"] = props.page["value"] = value;
        setInputValue(value);
    }

    function onClick(event: React.MouseEvent): void {
        //        if (inputType === "text")
        // //          (inputRef.current as HTMLInputElement).setSelectionRange(888, 888);
    }
    function onSubmit(event: FormEvent): void {
        event.preventDefault();
        if (submitActive) {
            console.log("targetLoc: " + targetLoc);
            history.push(targetLoc);
            //history.push("/" + props.page.language + "/" + props.page.target);
        }
    }

    /*function onKeyDown(event: React.KeyboardEvent): void {
        if (event.key === ' ' && props.page.inputType !== 'email') {
             event.preventDefault();
             props.page["value"] = inputValue + " ";
             setInputValue(inputValue + " ");
             //(inputRef.current as HTMLInputElement).value = inputValue + " ";
             (inputRef.current as HTMLInputElement).setSelectionRange(888, 888);
         }
    }*/

    function emailIsValid(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    function valueChanged(event: ChangeEvent): void {
        const inputType: string = props.page.inputType ? props.page.inputType : "text";
        let target: any = event.target;
        let value: string = target.value;
        console.log("check value...", value);

        console.log("ref val:", inputRef.current.value);

        /*let lng: number = (inputRef.current as HTMLInputElement).value.length;
        (inputRef.current as HTMLInputElement).value += "<suggestion>";
        (inputRef.current as HTMLInputElement).setSelectionRange(lng, lng + 14);*/
        props.page["value"] = value;
        setInputValue(value);

        let valid: boolean = true;
        if (inputType === "email") {
            valid = emailIsValid(value);
        }


        setSubmitActive(value && value.length > 0 && valid);


        if (props.page.postcode) {
            handlePostcode();
        }
        if (props.page.street && value.length >= 2) {
            handleStreet();
        }
        function handleStreet(): void {
            if ((stateData as any).previousInput && stateData["previousInput"].length > value.length) {
                stateData["previousInput"] = value;
                setStateData({ previousInput: value });
                return;
            }
            if (value.charAt(value.length - 1) === ' ') {
                setStateData({ previousInput: value });
                return;
            }
            setStateData({ previousInput: value });

            let postcode: string = props.config.getPageValue("postnummer");

            console.log("lookup street", value);
            let initialLength: number = value.length;
            PQAddressHelper.instance.suggestStreet(value, postcode, result => {
                console.log("suggested street:", result);
                if ((result as string).toLowerCase().indexOf(value.toLowerCase()) === 0) {
                    props.page["value"] = result;
                    setInputValue(result);
                    (inputRef.current as HTMLInputElement).setSelectionRange(initialLength, 900);
                }


            });
        }

        function handlePostcode(): void {
            if (value && value.length === 4) {
                console.log("lookup postcode", value);
                PQAddressHelper.instance.postcodeToCity(value, result => {
                    console.log("result: ", result);
                    props.config.setPageValue(props.page.postcode.target, result);

                });


            }
        }

    }
}



export function PQNumberInput(props: any) {
    let history = useHistory();
    const [submitActive, setSubmitActive] = useState(props.page["value"] && props.page["value"].length > 0);
    let [inputValue, setInputValue] = useState(props.page["value"]);
    const lang = props.config.language;
    const transContinue = props.config.config.languageLookup["continue"][lang]
    const className: string = "sh-button-input" + (submitActive ? "" : " sh-disabled2");

    inputValue = inputValue || "";
    var extraStyle: any = {}
    if (props.page.textAlign) {
        extraStyle["textAlign"] = props.page.textAlign;
    }
    if (props.page.width) {
        extraStyle["width"] = props.page.width;
        extraStyle["minWidth"] = props.page.width;
    }
    if (props.page.height) {
        extraStyle["height"] = props.page.height;
        extraStyle["minHeight"] = props.page.height;
    }



    //inputValue ??= "";
    return (
        <div>
            <div className="sh-header">
                {props.page?.header?.transText}
            </div>
            <form onSubmit={onSubmit}>
                <input style={extraStyle} type="number" className="sh-number" onChange={numberChanged} value={inputValue} />
            </form>

            <Link to={"/" + props.page.language + "/" + props.page.target} className={className} onClick={event => !submitActive && event.preventDefault()}>
                {transContinue}
            </Link >
        </div>

    );

    function onSubmit(event: FormEvent): void {
        event.preventDefault();
        if (submitActive) {
            history.push("/" + props.page.language + "/" + props.page.target);
        }
    }

    function numberChanged(event: ChangeEvent): void {
        let target: any = event.target;
        let value: any = target.value;
        console.log("check number...", target.value);
        setInputValue(target.value);
        /*        if (props.page.inputVar) {
                } */
        props.page["value"] = target.value;

        if (props.page.postcode) {
            handlePostcode();
        }
        function handlePostcode(): void {
            if (value && value.length === 4) {
                console.log("lookup postcode", value);
                PQAddressHelper.instance.postcodeToCity(value, result => {
                    console.log("result: ", result);
                    props.config.setPageValue(props.page.postcode.target, result);

                });


            }
        }



        if (props.page.id) {
            props.page.pbtext = target.value + "m²";
        }
        setSubmitActive(target.value && target.value.length > 0);
    }
}
