
const logUrl: string = "http://logs-01.loggly.com/inputs/955679e3-4d4e-49c3-8ac4-02e2fcd3663e/tag/http/";

export default class RLog {

    static instance = new RLog();
    static LOG_TO_LOGGLY: boolean = false;

    public static info(text: string, params: any = {}): void {
        let extra: string = params ? JSON.stringify(params) : "";
        console.log("RLog:", text, extra)
        RLog.instance.sendLog({ message: text, ...params, ...{ level: "info" } });
    }

    public static error(text: string, params: any = {}): void {
        let extra: string = params ? JSON.stringify(params) : "";
        console.log("RLog:", text, extra)
        RLog.instance.sendLog({ message: text, ...params, ...{ level: "error" } });
    }

    private sendLog(data: any): void {
        if (!RLog.LOG_TO_LOGGLY)
            return;


        fetch(logUrl, {
            method: 'POST', // or 'PUT'
            headers: {
                //'Content-Type': 'text/plain'
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("Received:", result);

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("Received error:", error);
                }
            )


    }

}
