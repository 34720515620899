export default class PQAddressHelper {
    public static instance: PQAddressHelper = new PQAddressHelper();

    suggestStreet(value: string, postcode: string, resultCallback: (result: any) => void) {
        let url: string = `https://dawa.aws.dk/vejnavne/autocomplete?q=${value}&per_side=1&noformat` + (postcode ? `&postnr=${postcode}` : "");
        this.getData(url, (result) => {

            if (result.length > 0) {
                resultCallback(result[0].tekst);

            }

        });
    }

    postcodeToCity(value: string, resultCallback: (result: string) => void) {
        this.getData(`https://dawa.aws.dk/postnumre/${value}?struktur=flad`, (result) => {

            resultCallback(result.navn);
        });
    }

    getData(url: string, callback: (result: any) => void) {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log("Received:", result);
                    callback(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("Received error:", error);
                }
            )
    }



}