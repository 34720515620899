import { isMobile } from "react-device-detect";
import PQConnect from "../PQConnect";
import RLog from "../RLog";

export class ConfigHelper {
    private static transDepth: number = 0;
    private pqConnect: PQConnect;

    public language: string = "";
    public config: any;
    public originalConfig: any;

    //public static flowPosition: number = -1;
    public static pingActive: boolean = false;

    //public static configUrl:string = "https://carpool.dk/test/sh01.json";
    //    public static configUrl: string = "http://localhost:3000/test/shc_1.json";
    public static configUrl: string = "./conf/shc_1.json";
    public static orderUrl: string = "./conf/order01.txt";

    onReadyResolver?: (value?: void | PromiseLike<void> | undefined) => void;

    currentPage: string = "";
    pingTimerId: NodeJS.Timeout;
    processTime: number;
    pingCount: number = 0;
    orderLines: string[];
    //currentFillIndex: number = 0;
    formSubmitted: any = {};
    loading: boolean = false;
    hidePriceBox: boolean = false;
    forceRender: () => void;
    //pageList: any[] = [];

    constructor() {
        this.loadOrderConfig().then(() => this.loadConfig());
        //this.pqConnect = new PQConnect("http://localhost:8080");
        //this.pqConnect = new PQConnect("http://192.168.1.168:8080");
        this.pqConnect = new PQConnect("https://prlaang.dk/sh");

        RLog.info("User connected", { level: "info" });
    }
    async loadOrderConfig() {
        let orderConf: string;
        try {
            let response: Response = await fetch(ConfigHelper.orderUrl);

            orderConf = await response.text();
        } catch (error) {
            RLog.error("Error retrieving order config from ", { url: ConfigHelper.orderUrl, errorMessage: error.message });
        }
        if (orderConf.toLowerCase().indexOf("<!doctype") !== -1) {
            RLog.error("Can't load order file");
            return;
        }
        console.log("order file:\n" + orderConf);
        this.orderLines = orderConf.split("\r\n");
        this.orderLines = this.removeComments(this.orderLines);



        console.log("lines", this.orderLines.join(" - "));

    }
    removeComments(input: string[]): string[] {
        return input.filter(value => value.indexOf("//") === -1);

    }

    public getLocale(id: string): string {
        if (this.config) {
            return this.config.languageLookup[id][this.language];
        }

        return "";
    }

    reorder(config: any) {
        let pages: object = config.pages as object;
        let page: any;
        let lastItem: string;
        if (this.orderLines) {
            let lineIndex: number = this.orderLines.length - 1;
            do {
                if (this.orderLines[lineIndex].length < 1)
                    continue;
                let thisItem: string[] = this.extractSubData(this.orderLines[lineIndex]);
                page = pages[thisItem[0]];
                if (thisItem.length > 1) {
                    for (let index = 1; index < thisItem.length; index++) {
                        const element = thisItem[index];
                        let pair: string[] = element.split(":");
                        page.buttons[Number(pair[0])].target = pair[1];

                        if (lastItem) {
                            pages[pair[1]].target = lastItem;
                        }
                    }
                }

                if (lastItem) {
                    if (page.target !== lastItem) {
                        console.log("Changing target for:", thisItem[0], "to:", lastItem, "from:", page.target);
                    }

                    /*if (lastItem === "thank_you") {
                        page.doSendAll = true;
                    }
                    else {
                        delete page.doSendAll;
                    }*/

                    page.target = lastItem;
                }

                lastItem = thisItem[0];
                //lineIndex--;

            } while (--lineIndex >= 0)
            config.startPage = lastItem;
        }

        console.log("Reorder ready!");
    }
    extractSubData(line: string): string[] {
        let sub: string[] = line.split("(");
        let buttons: string[] = [];
        if (sub.length > 1) {
            let part: string = sub[1]
            part = part.substring(0, part.lastIndexOf(")"));
            buttons = part.split(" ");
        }

        return [sub[0].trim(), ...buttons];
    }

    static setBgImageCall(callback: Function) {
        this.setBgImage = callback;
    }

    private static setBgImage: Function;

    startPing() {
        if (!ConfigHelper.pingActive) {
            this.processTime = Date.now();
            ConfigHelper.pingActive = true;
            this.pingTimerId = setInterval(() => {
                if (Date.now() > this.processTime + 10 * 60 * 1000) {
                    clearInterval(this.pingTimerId);
                    ConfigHelper.pingActive = false;
                }
                if (this.config?.uid) {
                    this.pqConnect.ping(this.config.uid);
                    //RLog("Sending ping", { pingCount: this.pingCount++ });
                }
            }, 15 * 1000);
        }
    }

    getPriceConfig(): any {
        let output: Array<any> = [];
        if (this.config && this.config.pages) {
            this.hidePriceBox = false;
            Object.keys(this.config.pages).forEach(key => {
                let page: any = this.config.pages[key];
                if (page.hidePricebox && !page.hidden && page.id) {
                    this.hidePriceBox = true
                }
                let item: any = {};
                switch (page.type) {
                    case "check":
                        (page.buttons as any[]).forEach((value, index) => {
                            if (value.isOn) {
                                item.text = value.transText;
                                item.value = 1;
                                item.cost = value.price;
                                output.push(item);
                                item = {};
                            }
                        });
                        break;

                    default:
                        item.text = page.pbtext ? page.pbtext : key;
                        item.value = (page.value ? page.value : 0);
                        item.cost = page.cost;
                        item.priceList = page.priceList;
                        output.push(item);
                        break;
                }
            });
        }

        return output;
    }

    processValues() {
        this.processTime = Date.now();
        let current: string = this.currentPage;
        if (!this.config.uid) {
            this.pqConnect.retrieveUID(this.config.version, uid => {
                if (!this.config.uid) {
                    this.config.uid = uid;
                    this.persist();
                }
                this.sendPage(current);
            });
        }
        else {
            this.sendPage(current);
        }
        this.startPing();
    }

    private sendPage(current: string) {
        let page = this.config.pages[current];
        let inputValue: any = page.input ? page.input : page.value;


        if (page.remove && this.config.pages[page.remove].fillIndex) {
            let index: number = this.config.pages[page.remove].fillIndex
            delete this.config.pages[page.remove].fillIndex;
            page.fillIndex = index;
            this.config.pageList[index] = page;
        }



        if (!page.hasOwnProperty('fillIndex') && page.noList !== true) {
            if (!this.config.pageList)
                this.config.pageList = [];

            page.fillIndex = this.config.currentFillIndex;
            this.config.pageList[this.config.currentFillIndex] = page;
            this.config.currentFillIndex++;
        }

        if (inputValue && inputValue !== page.storedValue) {
            page.uid = this.config.uid;
            if (page.position === undefined || page.remove)
                page.position = this.getNextPosition(page.remove, page.position);
            this.pqConnect.sendPage(page, () => page.storedValue = inputValue);
        }
        /*        if (page.doSendAll) {
                    this.pqConnect.sendComplete(this.config);
                    if (!this.config.devMode)
                        this.config = JSON.parse(JSON.stringify(this.originalConfig));
                    this.persist();
                } */
        if (this.config.pageList && page.noList !== true) {
            this.config.pageList[page.fillIndex] = page;

        }
    }
    getNextPosition(removePage?: string, lastPos?: number): number {

        if (removePage) {
            let pos: number = this.config.pages[removePage].position;
            delete this.config.pages[removePage].position;
            if (pos !== undefined) {
                return pos;
            }
            if (lastPos) {
                return lastPos;
            }
        }

        this.config.flowPosition++;
        return this.config.flowPosition;
    }

    setCurrentPage(target: string): void {
        console.log("setting new target:", target);
        if (!target) {
            this.currentPage = this.config.startPage;
        }
        else {
            this.currentPage = target;
            if (this.config && this.config.pages && this.config.pages[this.currentPage] && this.config.pages[this.currentPage]["bgImage"]) {
                //ConfigHelper.setBgImage(this.config.pages[this.currentPage]["bgImage"]);
                this.setBg({ src: this.config.pages[this.currentPage]["bgImage"], pos: this.config.pages[this.currentPage]["bgImagePos"] })
            }
        }

        if (this.currentPage === "oversigt") {
            if (this.config && this.config.pageList && this.config.pageList.length > 6) {
                this.config.readyForSubmission = true;
            } else {
                this.currentPage = this.config.startPage;
            }
        }

        if (this.config.readyForSubmission === true && this.config.pages[this.currentPage]["doSendAll"] && !this.formSubmitted[this.config.uid]) {
            this.formSubmitted[this.config.uid] = true;
            this.config.language = this.language;
            this.loading = true;
            //this.forceRender();
            let callTime: number = Date.now();
            this.pqConnect.sendComplete(this.config, () => {
                let that = this;
                let leftTime: number = 3000 - (Date.now() - callTime)
                console.log("Time left for loader:", leftTime);
                leftTime = leftTime <= 0 ? 500 : leftTime;
                setTimeout(() => {
                    that.loading = false;
                    that.forceRender();
                }, leftTime);
            });
            //delete this.config.uid;
            if (!this.config.devMode) {
                this.config = JSON.parse(JSON.stringify(this.originalConfig));
                this.parseTranslation();
            }
            this.config.readyForSubmission = false;
            this.config.reset = true;
            this.persist();
            if (window) {
                (window as any).awgtag('event', 'conversion', { 'send_to': 'AW-952659487/UaWkCM2PqnQQn9yhxgM' });
            }
        }
    }
    setBg(bgImage: { src: string; pos: string; }) {
        document.body.style.backgroundImage = "url(" + bgImage.src + ")";
        if (isMobile) {
            document.body.style.backgroundSize = "auto 100vh";
        } else {
            document.body.style.backgroundSize = "cover";
        }
        if (bgImage.pos && !isMobile) {
            document.body.style.backgroundPosition = bgImage.pos;
        } else {
            document.body.style.backgroundPosition = "top";
        }
    }

    public hidePage(pageName: string, doHide: boolean = true): void {
        this.config.pages[pageName].hidden = doHide;
    }

    getPageValue(pageName: string): any {
        return this.config.pages[pageName].value;
    }
    setPageValue(pageName: string, value: any) {
        this.config.pages[pageName].value = value;
    }

    getNext(): object {
        console.log("Get Next setup");
        this.config.pages[this.currentPage]["id"] = this.currentPage;
        this.config.pages[this.currentPage]["language"] = this.language;
        this.config.pages[this.currentPage]["header"] = { transText: this.config.pages[this.currentPage].transText };
        //this.config.pages[this.currentPage]["header"]
        return this.config.pages[this.currentPage];
    }

    whenReady(): Promise<void> {
        return new Promise((resolve) => {
            this.onReadyResolver = resolve
            if (this.config)
                resolve();
        });
    }

    isReady(): boolean {
        return this.config;
    }

    loadConfig() {
        fetch(ConfigHelper.configUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("Received:", result);

                    this.config = result;
                    this.config.flowPosition = -1;
                    this.config.currentFillIndex = 0;
                    this.reorder(result);

                    this.originalConfig = JSON.parse(JSON.stringify(result));

                    this.createMap(this.config);
                    this.prepareConfig();
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("Received error:", error);
                    RLog.error("Error loading config json", { errorMessage: error.message });
                }
            )
    }

    createMap(config: any) {
        console.log("config", config);
        let pages: any = config.pages;
        let next: string = config.startPage;
        let result: string = "";
        let page: any;
        do {
            page = pages[next];
            result += next;
            //result += next + " -> " + page.target + "\r\n";
            let sub: string = " ("
            let containsSub: boolean = false
            if (page.buttons) {
                (page.buttons as any[]).forEach((value, index, array) => {
                    if (value.target) {
                        if (containsSub)
                            sub += " ";

                        sub += "" + index + ":" + value.target;
                        //sub += pages[value.target].target;

                        containsSub = true;
                        //result += next + ":" + index + " -> " + page.target + "\r\n";
                    }

                });
            }
            if (containsSub) {
                sub += ")";
                result += sub;
            }
            result += "\r\n";
            next = page.target;

        } while (!page.doSendAll)

        console.log(result);
    }

    prepareConfig() {

        this.currentPage = this.config.startPage;
        if (this.language)
            this.parseTranslation();

        if (!this.config.disableLocalStorage)
            this.restoreLocalStorage();

        //this.checkUID();
        this.startPing();
        this.activateCallback();
    }

    checkUID() { // probably not needed
        let self = this;
        if (this.config.uid) {
            console.log("Stored UID", this.config.uid, "continuing session.");
            return;
        }

        this.pqConnect.retrieveUID(this.config.version, uid => {
            if (!self.config.uid) {
                self.config.uid = uid;
                self.persist();
            }
        });
    }
    activateCallback() {
        if (this.onReadyResolver) {
            (this.onReadyResolver as Function)();
        }


    }

    persist(): void {
        if (this.config) {
            localStorage.setItem("SH-FORM-DATA", JSON.stringify(this.config));
        }
    }

    restoreLocalStorage() {
        let store: string = localStorage.getItem("SH-FORM-DATA");
        let data: any = store ? JSON.parse(store) : {};

        if (this.config.version === data.version && this.config.restoreLocal) {
            this.config = { ...this.config, ...data };
        }
    }
    parseTranslation(obj: any = null) {
        if (!obj) {
            obj = this.config
        }

        //if (obj[this.language])
        //obj["transText"] = obj[this.language]

        Object.keys(obj).forEach(key => {

            if (key === this.language) {
                obj["transText"] = obj[key];
            }
            else if (typeof obj[key] === "object" && key !== 'pageList') {
                //console.log("DEPTH:", ConfigHelper.transDepth++);
                ConfigHelper.transDepth++;
                if (ConfigHelper.transDepth < 10) {
                    this.parseTranslation(obj[key]);
                }
                ConfigHelper.transDepth--;
                //console.log("DEPTH:", ConfigHelper.transDepth--);
            }
        });

    }

    setLanguage(lang: string) {
        this.language = lang;
        if (this.currentPage)
            this.parseTranslation();
    }

}
