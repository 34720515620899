import React from 'react';
import { Link } from 'react-router-dom';

export default function PQOverview(props: any) {
    console.log("Render overview now", props);
    let list: any[] = [];
    props.config.config.overviewPage = "/" + props.page.language + "/" + props.page.id;
    props.config.config.pageList = props.config.config.pageList || [];

    (props.config.config.pageList as any[]).forEach((item, index) => {
        let value: string = item.input ? item.input : item.value;
        //value = value || "-";
        /*let price: number = 0;
        if (item.cost && item.value) {
            price = item.cost * item.value;
        }*/
        if (item.type !== "overview" && item.noList !== true && value && item.hidden !== true) {
            var target: string = "/" + item.language + "/" + item.id;
            list.push(
                /*<Link to={"/" + props.page.language + "/" + props.page.target} className={className} onClick={event => !submitActive && event.preventDefault()}>
                                {transContinue}
                            </Link >*/

                <tr key={"tr" + index} className="sh-overview-tr">
                    <td className="sh-overview-item sh-overview-left">
                        <Link className="sh-overview-link" to={target} style={{ textDecoration: "none" }}>
                            {"" + item.transText}
                        </Link></td>

                    <td className="sh-overview-item sh-overview-right">{"" + value}</td>
                </tr >
            );

        }
    });


    return <div>
        <div style={{ height: "3.1em" }}></div>
        <div className="sh-overview-box">
            <div className="sh-overview-title">{props.page.transText}</div>
            <table cellSpacing="0" cellPadding="0"><tbody>{list}</tbody></table>
        </div>
        <div style={{ height: "1rem" }}></div>
    </div>
}
