import React, { Component, MouseEvent } from 'react'
import { HashRouter, Link } from "react-router-dom";
import { PageProp } from './PageProp'
//import { Button } from '@material-ui/core'

export default class PQMulti extends Component<PageProp> {
    buttons: React.ReactNode;
    buttonRef: Array<any> = [];
    previousValue: any;
    id: any = "";

    currentHistory: any;

    constructor(props: PageProp) {
        super(props)
        console.log("PQMulti constructor");

    }

    render() {
        if (this.props.page.id !== this.id) {
            this.buttons = null;
            this.buttonRef = [];
        }

        return (
            <HashRouter>
                <div>
                    {this.generateButtons()}
                </div>
            </HashRouter>


            /*<div>
                {this.generateButtons()}
            </div>*/

        )
    }

    onButtonClick(event: MouseEvent): void {
        //this.props.page.forceUpdateAll();
        //event.preventDefault();

        //return;

        let id: number = (event.target as any).id;

        if (this.previousValue && this.buttonRef[id] !== this.previousValue) {
            this.props.config.hidePage(this.previousValue.target);
        }

        if (this.buttonRef[id].target) {
            this.props.config.hidePage(this.buttonRef[id].target, false);
        }

        this.buttonRef.forEach(value => value.selected = false);
        this.buttonRef[id].selected = true;
        this.props.page.input = this.buttonRef[id].transText;
        //event.preventDefault();
        console.log("onButtonClick: event.target", event.target);
        this.props.onSelection(this.buttonRef[id]);

        if (this.buttonRef[id].cost !== undefined) {
            this.props.page.cost = this.buttonRef[id].cost;
        }


    }

    generateButtons(): React.ReactNode {
        if (!this.buttons) {
            this.previousValue = undefined;
            this.buttons = (this.props.page.buttons as Array<any>).map((value, index) => {
                value.refId = index;
                this.buttonRef.push(value);

                let targetLoc: string = "/" + this.props.page.language + "/" + this.props.page.target;

                if (value.selected && value.target && value.target !== this.props.page.target) {
                    console.log("target:", this.props.page.target);
                    this.previousValue = value;
                }

                if (value.target) {
                    targetLoc = "/" + this.props.page.language + "/" + value.target;
                }
                else if (this.props.config.config.overviewPage) {
                    targetLoc = this.props.config.config.overviewPage;
                }

                if (value.subTarget) {
                    console.log("info:", value.info);
                    targetLoc = "/" + this.props.page.language + "/" + this.props.page.id + "/" + value.subTarget;
                }
                let className: string = "sh-button";
                if (value.selected) {
                    className += " sh-button-sel";
                }

                let buttonSize: string = this.props.page.buttonSize || "4em";
                let buttonStyle: any = { minWidth: buttonSize };
                if (this.props.page.buttonStyles) {
                    buttonStyle = { ...buttonStyle, ...this.props.page.buttonStyles };
                }

                return <Link style={buttonStyle} to={targetLoc} className={className} role="button" key={"key" + value.transText} id={value.refId} onClick={this.onButtonClick.bind(this)} >{value.transText} </ Link >
                //return <Button key={value.transText} id={value.refId} onClick={this.onButtonClick.bind(this)}> {value.transText}</ Button >

            });
        }
        return this.buttons;
    }

}

                    //const PQMulti = withRouter(PQMultiInternal);
//export default PQMulti;
