import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export class PriceInfoRefresher {
    public static StateChanger: Function;
    public static refresh(): void {
        if (this.StateChanger)
            this.StateChanger({});

    }

}

function extractPricePoint(table: any[], kvm: number): number | undefined {
    let result = table.find((value) => {
        if (kvm <= value[0]) {
            return true;
        }

        return false;
    });

    return result ? result[1] : 0;
}

export default function PriceInfoBox(props: any) {
    const [stateChange, setStateChange] = useState({});


    PriceInfoRefresher.StateChanger = setStateChange;

    console.log(stateChange);

    let output: Array<any> = [];
    let data: Array<any> = props.config?.getPriceConfig() || [];
    let count: number = 0;
    let total: number = 0;
    let pcent: number = 0;
    if (data && data.length) {
        output = data.filter(value => (value.cost && value.cost > 0 && value.value)).map(value => {
            if (typeof value.value === "string" && value.value.indexOf("%") !== -1) {
                pcent = parseInt(value.value);

                return <div className="divTableRow" key={"key" + (count++)}>
                    <div className="divTableCell">{value.text}</div>
                    <div className="divTableCellValue"> + {pcent + "%"}</div>
                </div >
            }

            let val: number = Math.floor(value.value * value.cost);

            if (value.priceList) {
                val = extractPricePoint(value.priceList, Number(value.value));
            }


            total += val


            return <div className="divTableRow" key={"key" + (count++)}>
                <div className="divTableCell">{value.text}</div>
                <div className="divTableCellValue"> + {val} kr.</div>
            </div >
        });
    }

    if (pcent > 0) {
        total *= (pcent / 100) + 1;
    }


    let totalPris: string = "";
    let totalPrisTekst: string = "";
    let fradragPrisTekst: string = "";
    let fradragPris: string = "";
    let title: string = "";
    if (output.length > 0) {
        title = "Din pris:";
        totalPris = "" + Math.floor(total) + "kr.";
        totalPrisTekst = "Total:";
        fradragPrisTekst = "Inkl. hårdværkerfradag:";
        fradragPris = "" + Math.floor(total * 0.74) + "kr.";
    }
    //title = "TEST:" + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    //title = title.substr(0, 10);
    let innerPricesClass: string = "";
    let mainClassName: string = "sh-price-box";

    let hideBox: boolean = props.config && (props.config.currentPage === 'thank_you' || props.config.currentPage === 'over200');

    if (props.config?.hidePriceBox) {
        innerPricesClass = "sh-invisible";
        mainClassName += " sh-no-border-bg";
    }

    if (total <= 0 || hideBox) {
        mainClassName += " sh-hide-me";
    }

    let submitStyle = {};

    if (props.config && props.config.config && !props.config.config.readyForSubmission) {
        submitStyle["display"] = "none";
    }

    if (!props.config) {
        return <div></div>
    }

    return <div className={mainClassName}>
        <div className={innerPricesClass}>
            {title}
            <div className="divTable"><div className="divTableBody">{output}</div></div>
            <div className="sh-price-spacer"></div>
            <div className="sh-price-total-text">{totalPrisTekst}<br />
                {fradragPrisTekst}
            </div>
            <div className="sh-price-total">{totalPris}<br />
                {fradragPris}
            </div>
            <div className="sh-price-inkl">{props.config?.getLocale("priceboxSub1") || ""}<br />
                {props.config.getLocale("priceboxSub2") || ""}{fradragPris} <br />{props.config?.getLocale("priceboxSub3") || ""}
            </div>
        </div>
        <div className="sh-final-submit " style={submitStyle}><Link className="sh-button sh-final-submit-button" to="/DK/thank_you">{props.config?.getLocale("submitFormButton") || ""}</Link></div>
        <div style={submitStyle} className="sh-gdpr-box">{props.config?.getLocale("priceboxSub4") || ""}</div>
    </div>


}
