import React, { Component } from 'react'

export default class PQContainer extends Component {
    render() {
        return (
            <div className="sh-main-container">
                {this.props.children}

            </div>

        )
    }
}
